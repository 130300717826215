<script>
import CardListTemplate from '../../../components/Lists/CardListTemplate.vue';
import AnyPageTemplate from '../../../components/PageTemplates/AnyPage.vue';
import FederalNumber from '../../../components/Panels/FederalNumber.vue';
import CompetitionsConfigurationHelper from '../../../services/helpers/competitionsConfigurationHelper';
import LicenseeEditDialog from '../../../components/Dialogs/LicenseeEditDialog.vue';
import StaffMemberEditDialog from '../../../components/Dialogs/StaffMemberEditDialog.vue';
import { STAFF, PEOPLE } from '../../../LocalDB';
import NavigateBackIcon from '../../../components/Icons/NavigateBackIcon.vue';

export default{
    components:{ AnyPageTemplate, CardListTemplate, FederalNumber, LicenseeEditDialog, StaffMemberEditDialog, NavigateBackIcon},
    data(){
        return {
            competition_id: '',
            competition: { name: '' },
            selectedMembers: [],
            licensee: null,
            displayLicenseeDialog: false,
            editingMember: {
                federal_number: '',
                name: '',
                year: 0
            },
            displayMemberEditDialog: false,
            memberEditDialogForCreationMode: false,
            displayMemberEditDialogTitle: '',
            displayMemberEditDialogSuggestions: [],
        };
    },
    methods:{
        async refresh(){
            await this.$showRefresher();
            this.competition = await CompetitionsConfigurationHelper.get(this.competition_id);
            this.selectedMembers = await CompetitionsConfigurationHelper.getStaffWithCapabilitiesAsync(this.competition_id);
            this.$hideRefresher();
        },
        create(){
            this.memberEditDialogForCreationMode = true;
            this.displayMemberEditDialogTitle = this.$t("Ajout d'un membre");
            this.displayMemberEditDialogSuggestions = this.getEditDialogSuggestions();
            this.displayMemberEditDialog = true;
        },
        async remove(staff_id){
            var confirmed = await this.$bvModal.msgBoxConfirm(this.$t('pages.competition-admin.staff-members.remove-confirm'), {
                title: this.$t('dialogs.confirm.title'),
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                cancelVariant: 'secondary',
                okTitle: this.$t('dialogs.confirm.yes-button'),
                cancelTitle: this.$t('dialogs.confirm.no-button'),
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            });
            console.log('confirmed', confirmed);
            if(confirmed){
                await this.showSaver();
                await CompetitionsConfigurationHelper.dettach_staff_Async(this.competition_id, staff_id);
                await this.refresh();
                this.hideSaver();
             }
        },
        edit_licensee(member){
            console.log('edit_licensee', member.LICENSEE);
            this.editingMember = {
                federal_number: member.PEOPLE.federalNumber,
                name: member.PEOPLE.name,
                year: this.competition.year
            };
            this.displayLicenseeDialog = true;
        },
        getEditDialogSuggestions(){
            const staffs = STAFF.query()
                        //.where(s => !this.competition.isFederal || s.isFederalValid)
                        .get()
                        .map(s => {
                            var mapped = s;
                            mapped.matches = '*';
                            if(s.HasAnyCapabilityForCompetitionTypeAndScopeAndLevels(this.competition.competitionType, this.competition.scope, this.competition.levels))
                                mapped.matches += '*';
                            if(this.competition.isFederal && s.isFederalValid)
                                mapped.matches += '*'
                            mapped.forSuggestion = mapped.matches + ' ' + s.name + (s.PEOPLE.isFederal ? ' - ' + s.PEOPLE.federalNumber : '');
                            return mapped;
                        });
            const staffsIds = staffs.map(s => s.id);
            const peoples = PEOPLE.query()
                        .where(p => !staffsIds.includes(p.id))
                        .get()
                        .map(p => {
                            var mapped = p;
                            mapped.matches = '';
                            mapped.forSuggestion = p.name + (p.isFederal ? ' - ' + p.federalNumber : '');
                            return mapped;
                        })
            const competitionStaffsId = this.selectedMembers.map(m => m.staff_id);
            /*const unordered_results = staffs.concat(peoples).filter(s => !competitionStaffsId.includes(s.id));
            var collator = new Intl.Collator()
            const results = unordered_results.sort((a,b) => { var o1 = collator.compare(a.matches, b.matches);
                return o1 == 0 ? collator.compare(a.name, b.name) : o1; })
            return results;*/
            return staffs.concat(peoples).filter(s => !competitionStaffsId.includes(s.id));
        },
        async navigateBack_click(){
            await this.$showLoader();
            this.$router.push({name: 'compet-admin-config', params: { id: this.competition_id } })
        }
    },
    async mounted(){
        this.competition_id = this.$route.params.id;
        await this.refresh();
        this.$hideLoader();
    },
    watch:{
      '$route.params.id': {
        handler: function(id){
            this.competition_id = id;
            this.refresh();
        },
        deep: true,
        immediate: true
      } 
    }
}
</script>

<template>
    <any-page-template>
        <template #main-title-caption>{{ competition.name }}</template>
        <template #secondary-title-caption><navigate-back-icon @click="navigateBack_click" /> {{ $t('pages.competition-admin.staff-members.title') }}</template>
        <template #page-intro>
            <div v-html="$t('pages.competition-admin.staff-members.intro')"></div>
            <br/>
        </template>
        <card-list-template>
            <template #header-commands-left>
                <b-button size="sm" @click="create">{{ $t('pages.competition-admin.staff-members.add') }}</b-button>
            </template>
            <template #header-commands-right>
                <b-button size="sm" @click="refresh">{{ $t('pages.competition-admin.staff-members.refresh') }}</b-button>
            </template>

            <b-card v-for="member in selectedMembers" :key="member.id">
                <div style="display:flex; justify-content: space-between;">
                    <div>
                        <b>{{ member.firstname }} {{ member.lastname }}</b>&nbsp;<federal-number :is-federal="competition.isFederal" v-model="member.LICENSEE" force-edit @edit="edit_licensee(member)"></federal-number>
                    </div>
                    <div>
                        <b-button size="sm" @click="remove(member.staff_id)" variant="danger">Suppr.</b-button>
                    </div>
                </div>
                <div>
                    <b-badge class="capability capability-judge" v-if="member.CAPABILITIES.isJudge">Juge</b-badge>
                    <b-badge class="capability capability-judge capability-shadow" v-if="member.CAPABILITIES.isJudgeShadow">Juge ombre</b-badge>
                    <b-badge class="capability capability-field-director" v-if="member.CAPABILITIES.isFieldDirector">Directeur de terrain</b-badge>
                    <b-badge class="capability capability-field-director capability-shadow" v-if="member.CAPABILITIES.isFieldDirectorShadow">Directeur de terrain ombre</b-badge>
                    <b-badge class="capability capability-scorer" v-if="member.CAPABILITIES.isScorer">Scoreur</b-badge>
                    <b-badge class="capability capability-scorer capability-shadow" v-if="member.CAPABILITIES.isScorerShadow">Scoreur ombre</b-badge>
                </div>
            </b-card>
        </card-list-template>

        <template #dialogs-placeholder>
            <licensee-edit-dialog
                v-model="displayLicenseeDialog"
                :federalNumber = 'editingMember.federal_number'
                :year = 'editingMember.year'
                :peopleName = editingMember.name
            ></licensee-edit-dialog>

            <staff-member-edit-dialog
                v-model="displayMemberEditDialog"
                :creation-mode="memberEditDialogForCreationMode"
                :title="displayMemberEditDialogTitle"
                :competition_id="competition_id"
                :search-suggestions="displayMemberEditDialogSuggestions"
                @staffAttached="refresh"
            >
            </staff-member-edit-dialog>
        </template>
    </any-page-template>
</template>

<style>
    .badge.capability{
        margin-right: 10px;
        padding: 5px;
        border:solid 2px green;
        color: rgb(1, 68, 1);
    }

    .badge.capability-judge{
        background-color: rgb(244, 244, 158);
    }
    .badge.capability-field-director{
        background-color: rgb(122, 211, 243);
    }    
    .badge.capability-scorer{
        background-color: pink;
    }    
    .badge.capability.capability-shadow{
        border:solid 2px orange;
        color:rgb(78, 51, 1);
    }
    .badge.capability-shadow.capability-judge{
        background-color: rgb(247, 247, 199);
    }    
    .badge.capability-shadow.capability-field-director{
        background-color: rgb(176, 227, 246);
    }    
    .badge.capability-shadow.badge.capability-scorer{
        background-color: rgb(253, 229, 233);
    }   
</style>